import React from 'react'
import styled from 'styled-components'
import { AppBar, Box, Toolbar } from '@mui/material'

import Logo from 'components/atoms/Logo'
import Search from 'components/molecules/Search'
import Button from 'components/atoms/Button'
import Space from 'components/atoms/Space'
import { useNavigate } from 'react-router'
import Color from 'utils/Color'
export interface Props {}

const Component: React.VFC = () => {
  const navi = useNavigate()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SAppBar position="static" color="default">
        <Toolbar>
          <Logo onClick={() => navi('/')} />
          <Search />
          <Box sx={{ flexGrow: 1 }} />
          <Space margin="0 4px">
            <Button
              onClick={() => navi('/audit')}
              text="AUDIT APOSTILLE"
              fill
            />
          </Space>
          <Button
            onClick={() => navi('/create')}
            text="CREATE APOSTILLE"
            fill
          />
        </Toolbar>
      </SAppBar>
    </Box>
  )
}

export default Component

const SAppBar = styled(AppBar)`
  box-shadow: 0px 0px 0px 0px white;
  box-sizing: border-box;
  height: 64px;
  background: white !important;
  border-bottom: solid 1px ${Color.grayscale};
  box-shadow: none !important;
`
