import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { AppBar, Box, IconButton } from '@mui/material'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import TwitterIcon from '@mui/icons-material/Twitter'

import Color from 'utils/Color'
import mediaQuery from 'utils/MediaQuery'

export interface Props {}

const Component: React.VFC = () => {
  const isMobile = useMedia(mediaQuery.mobile)

  if (isMobile) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <SAppBar position="static" color="default">
          <Mobile>
            <MInfo2>
              <InfoWrapper
                onClick={() =>
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSckfh-d6GXRT6FWgqVtq0G_t70R4JswV0ahso2pdALH6ZCjWQ/viewform'
                  )
                }>
                <IconButton>
                  <ContactSupportIcon />
                </IconButton>
                <Text>お問い合わせ</Text>
              </InfoWrapper>

              <InfoWrapper
                onClick={() => window.open('https://twitter.com/incopening')}>
                <IconButton>
                  <TwitterIcon />
                </IconButton>
                <Text>Twitter</Text>
              </InfoWrapper>
            </MInfo2>
            <MInfo>
              <Text
                onClick={() => window.open('https://www.opening-line.co.jp/')}>
                © 2021 OpeningLine, inc
              </Text>
            </MInfo>
          </Mobile>
        </SAppBar>
      </Box>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SAppBar position="static" color="default">
        <Wrapper>
          <Info2>
            <InfoWrapper
              onClick={() =>
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSckfh-d6GXRT6FWgqVtq0G_t70R4JswV0ahso2pdALH6ZCjWQ/viewform'
                )
              }>
              <IconButton>
                <ContactSupportIcon />
              </IconButton>
              <Text>お問い合わせ</Text>
            </InfoWrapper>

            <InfoWrapper
              onClick={() => window.open('https://twitter.com/incopening')}>
              <IconButton>
                <TwitterIcon />
              </IconButton>
              <Text>Twitter</Text>
            </InfoWrapper>
          </Info2>
          <Info>
            <Text
              onClick={() => window.open('https://www.opening-line.co.jp/')}>
              © 2021 OpeningLine, inc
            </Text>
          </Info>
        </Wrapper>
      </SAppBar>
    </Box>
  )
}

export default Component

const SAppBar = styled(AppBar)`
  box-shadow: 0px 0px 0px 0px white;
  background: white !important;
  box-shadow: none !important;
  height: 64px;
  border-top: solid 1px ${Color.grayscale};
`

const Wrapper = styled('div')`
  margin 0 20vw;
  display: flex;
  justify-content: space-between;
  height: 64px;
`
const Info = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MInfo = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 16px;
`
const Info2 = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const MInfo2 = styled('div')`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const Text = styled('div')`
  color: ${Color.text.default}
  font-size: 10px;
`
const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 8px;
`

const Mobile = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 128px;
`
