import { atom } from 'recoil'
import { ApostilleData } from 'utils/Domain'

export const apostilleInfoState = atom<ApostilleData>({
  key: 'ApostilleInfoAtom',
  default: {
    id: '',
    filename: '',
    title: '',
    src: '',
    timestamp: '',
    signer: '',
    tags: '',
  },
})
