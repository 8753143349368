import React, { useState } from 'react'
import styled from 'styled-components'
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Modal,
  Paper,
  TextField,
} from '@mui/material'
import Button from 'components/atoms/Button'

import Logo from 'components/atoms/Logo'
import { useNavigate } from 'react-router'
import Color from 'utils/Color'

import MUISearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

export interface Props {}

const Component: React.VFC = () => {
  const navi = useNavigate()
  const [open, setOpen] = useState(false)
  const [word, setWord] = useState('')

  const search = () => {
    if (word.length === 64) {
      // console.log('txHash')
      navi(`/apostille/${word}`)
    } else if (word.replaceAll('-', '').length === 39) {
      // console.log('addr')
      navi(`/account/${word}`)
    } else {
      // console.log('other')
      navi(`/tag/${word}`)
    }
    setTimeout(() => {
      setOpen(false)
      setWord('')
    }, 100)
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SAppBar position="static" color="default">
        <Toolbar>
          <Logo onClick={() => navi('/')} />
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => setOpen(true)}>
            <MUISearchIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => navi('/audit')}>
            <VerifiedUserIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => navi('/create')}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </SAppBar>
      <Modal open={open} onClose={() => setOpen(false)}>
        <SPaper>
          <Text>検索</Text>
          <TextField
            variant="filled"
            fullWidth
            label="TxHash / Signer / Tags ..."
            onChange={(e) => setWord(e.target.value)}
            value={word}
          />
          <Wrapper>
            <Button text="SEARCH" onClick={search} />
          </Wrapper>
        </SPaper>
      </Modal>
    </Box>
  )
}

export default Component

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin: 16px;
`

const SAppBar = styled(AppBar)`
  box-shadow: 0px 0px 0px 0px white;
  box-sizing: border-box;
  height: 64px;
  background: white !important;
  border-bottom: solid 1px ${Color.grayscale};
  box-shadow: none !important;
`

const SPaper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  transform: translate(-50%, -50%);
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Text = styled('div')`
  font-size: 24px;
  color: ${Color.text.default};
`
