import React from 'react'
import styled from 'styled-components'

import CircularProgress from '@mui/material/CircularProgress'

const Component: React.VFC = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )
}

export default Component

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
