import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { useMedia } from 'react-use'

import Header from 'components/organisms/Header'
import MHeader from 'components/organisms/MHeader'
import Space from 'components/atoms/Space'
import Footer from 'components/organisms/Footer'
import { ApostilleData } from './Domain'
import { apostilleInfoState } from './State/Atom'
import mediaQuery from 'utils/MediaQuery'

interface Props {
  page: React.ReactNode
  title: string
}

const Layout: React.VFC<Props> = ({ page, title }) => {
  const apostilleInfo = useRecoilValue<ApostilleData>(apostilleInfoState)
  const isMobile = useMedia(mediaQuery.mobile)

  if (isMobile) {
    return (
      <>
        <Helmet
          title={`OpenApostille | ${title}`}
          meta={[
            { name: 'description', content: 'OpenApostille' },
            {
              property: 'og:title',
              content: `OpenApostille | ${apostilleInfo.title}`,
            },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: `` },
            {
              property: 'og:image',
              content: `${process.env.PUBLIC_URL}/logo.svg`,
            },
            {
              property: 'og:description',
              content: `OpenApostille | ${apostilleInfo.filename} ${apostilleInfo.id}`,
            },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@IncOpening' },
          ]}
        />
        <MHeader />
        <Space margin="32px 16px 0px">
          <MWrapper>{page}</MWrapper>
        </Space>
        <Footer />
      </>
    )
  }
  return (
    <>
      <Helmet
        title={`OpenApostille | ${title}`}
        meta={[
          { name: 'description', content: 'OpenApostille' },
          {
            property: 'og:title',
            content: `OpenApostille | ${apostilleInfo.title}`,
          },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: `` },
          {
            property: 'og:image',
            content: `${process.env.PUBLIC_URL}/logo.svg`,
          },
          {
            property: 'og:description',
            content: `OpenApostille | ${apostilleInfo.filename} ${apostilleInfo.id}`,
          },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@IncOpening' },
        ]}
      />
      <Header />
      <Space margin="32px 20vw 0px">
        <Wrapper>{page}</Wrapper>
      </Space>
      <Footer />
    </>
  )
}

export default Layout

// 全体 - Header - margin - Footer
const Wrapper = styled('div')`
  width: 60vw;
  min-height: calc(100vh - 64px - 32px - 64px);
`
const MWrapper = styled('div')`
  width: calc(100vw - 32px);
  min-height: calc(100vh - 64px - 32px - 64px);
`
