import React from 'react'
import styled from 'styled-components'

export interface Props {
  children: React.ReactNode
}

const Component: React.VFC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Component

const Wrapper = styled('div')`
  padding: 4px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`
