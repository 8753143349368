import React, { useRef } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import MUISearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'

import IconWrapper from 'components/atoms/IconWrapper'
import { IconButton } from '@mui/material'

const Search: React.VFC = () => {
  const navi = useNavigate()
  const inputEl = useRef<HTMLInputElement>(null!)

  const search = () => {
    const text = inputEl.current.value
    if (text.length === 64) {
      // console.log('txHash')
      navi(`/apostille/${text}`)
    } else if (text.replaceAll('-', '').length === 39) {
      // console.log('addr')
      navi(`/account/${text}`)
    } else {
      // console.log('other')
      navi(`/tag/${text}`)
    }
  }
  return (
    <Root>
      <SInputBase
        placeholder="TxHash / Signer / Tags ..."
        inputProps={{ 'aria-label': 'search' }}
        inputRef={inputEl}
        onKeyPress={(e) => e.key === 'Enter' && search()}
      />
      <IconButton onClick={search}>
        <SSearchIcon />
      </IconButton>
    </Root>
  )
}

export default Search

const SearchIcon = () => <IconWrapper children={<MUISearchIcon />} />

const Root = styled('div')`
  position: relative;
  display: flex;

  width: 40vw;

  align-items: center;
  margin: 0px 32px;
  padding: 0px 8px;

  border: solid 1px;
  border-radius: 4px;
`
const SInputBase = styled(InputBase)`
  width: 100%;
`

const SSearchIcon = styled(SearchIcon)`
  margin: 2px;
`
