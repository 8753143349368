import Loading from 'components/atoms/Loading'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Layout from './Layout'

const Home = lazy(() => import('../pages/Home'))
const Account = lazy(() => import('../pages/Account'))
const Tag = lazy(() => import('../pages/Tag'))
const Detail = lazy(() => import('../pages/Detail'))
const Create = lazy(() => import('../pages/Create'))
const Audit = lazy(() => import('../pages/Audit'))

const Router: React.VFC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout title="Home" page={<Home />} />} />
          <Route
            path="/apostille/:id"
            element={<Layout title="Apostille" page={<Detail />} />}
          />
          <Route
            path="/account/:addr"
            element={<Layout title="Search" page={<Account />} />}
          />
          <Route
            path="/tag/:tag"
            element={<Layout title="Search" page={<Tag />} />}
          />
          <Route
            path="/create"
            element={<Layout title="Create" page={<Create />} />}
          />
          <Route
            path="/audit"
            element={<Layout title="Audit" page={<Audit />} />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default Router
